const KEY = [];

let nextIndexAction = 0;
let nextIndexObserve = 0;

const addKey = (name, eventKey, observe) => {
  const key = { name, eventKey, observe };
  let index;
  if (observe) {
    index = nextIndexObserve;
    nextIndexObserve++;
  } else {
    index = nextIndexAction;
    nextIndexAction++;
  }
  key.index = index;
  KEY.push(key);
};

addKey('1', '1', false);
addKey('2', '2', false);
addKey('3', '3', false);
addKey('4', '4', false);
addKey('5', '5', false);
addKey('6', '6', false);
addKey('7', '7', false);
addKey('8', '8', false);

addKey('Q', 'q', true);
addKey('W', 'w', true);
addKey('E', 'e', true);
addKey('R', 'r', true);
addKey('T', 't', true);
addKey('Y', 'y', true);
addKey('U', 'u', true);
addKey('I', 'i', true);

const getKeyFromEvent = (event) => {
  const eventKey = event.key;
  return KEY.find(key => key.eventKey === eventKey);
}

const getKeyFromIndexAndObserve = (index, observe) => {
  return KEY.find(key => key.index === index && key.observe === observe);
};

export { getKeyFromEvent, getKeyFromIndexAndObserve };
