import { GUI, saveState } from './state';

const notify = (note) => {
  if (!note) {
    note = '';
  }
  note = note.trim();
  if (note === '') {
    note = '...';
  }
  GUI.note = note;
  saveState();
};

export default notify;
