import { GUI, UNLOCKABLE, RESOURCE, HARDWARE, CAMERA } from './state';
import { OPTION } from './option';
import getVisual from './getVisual';
import { getKeyFromIndexAndObserve } from './key';

const createReactState = () => {
  const reactState = {};

  reactState.note = GUI.note;
  reactState.visual = getVisual();
  reactState.options = getOptions();
  reactState.stats = getStats();
  reactState.cameraLevel = CAMERA.level;
  reactState.statsVisible = !UNLOCKABLE.solarcells;

  return reactState;
}

const getOptions = () => {
  const options = [...getOptionsWhereObserve(false), ...getOptionsWhereObserve(true)];
  if (options.length === 0) {
    options[0] = {
      id: -1,
      name: 'end of game',
      key: getKeyFromIndexAndObserve(0, false)
    };
  }
  return options;
}

const getOptionsWhereObserve = (observe) => {
  let index = 0;
  return OPTION
  .filter(option => option.visible() && option.observe === observe)
  .map(option => {
    const id = option.id;
    const name = option.name();
    const key = getKeyFromIndexAndObserve(index, observe);
    index++;
    return { id, name, key }
  });
}

const getStats = () => {
  const statsHardware = getStatsHardware();
  const statsResource = getStatsResource();
  return [...statsHardware, ...statsResource]
}

const getStatsHardware = () => {
  return Object.values(HARDWARE)
  .filter(hardware => hardware.scanned && hardware.listed)
  .map(hardware => {
    const name = getStatsHardwareName(hardware);
    const percent = Math.round(hardware.current / hardware.max * 100);
    const amount = `${percent}%`;
    return { name, amount };
  });
}

const getStatsHardwareName = (hardware) => {
  if (UNLOCKABLE.workshop) {
    return `${hardware.name} t${hardware.level}`;
  } else {
    return hardware.name;
  }
}

const getStatsResource = () => {
  return Object.entries(RESOURCE)
  //.filter(([key, value]) => value > 0)
  .map(([key, value]) => {
    const name = key;
    const amount = `${value}x`;
    return { name, amount };
  });
}

export default createReactState;
