import { saveState, STATE } from './state';

window.state = STATE;

// https://stackoverflow.com/questions/6393943/convert-javascript-string-in-dot-notation-into-an-object-reference
function index(obj, is, value) {
  if (typeof is === 'string')
    return index(obj, is.split('.'), value);
  else if (is.length === 1 && value !== undefined)
    return obj[is[0]] = value;
  else if (is.length === 0)
    return obj;
  else
    return index(obj[is[0]],is.slice(1), value);
}

const setState = (key, value) => {
    index(STATE, key, value);
    saveState();
    window.location.reload();
};

window.setState = setState;

// setState('player.location', 'FARM_FENCE');
// setState('player.hardware.camera.level', 3);
// setState('player.unlockable.farmfenceopen', true);
