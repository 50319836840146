import Location from './Location';
import { PLAYER, CAMERA, UNLOCKABLE } from './state';
import Mode from './Mode';
import { getDaveVisualText, getBrokenVisualText } from './daveutil';

let image;
let text;
let external;

const internal = () => {
  image = null;
  text = 'camera error';
  external = true;

  const mode = Mode.get();

  if (mode === Mode.UPDATE) {
    external = false;
    image = 'update.jpg';
    text = 'Software Update';
    return;
  }

  if (PLAYER.location === Location.WELCOME) {
    text = 'play using the number keys or clicking below';
    return;
  }

  if (mode === Mode.ASLEEP) {
    external = false;
    if (CAMERA.scanned) {
      text = '* darkness *';
    } else {
      text = '...';
    }
    return;
  }

  if (PLAYER.location === Location.FARM_DOCK) {
    image = 'farm-dock.jpg';
    text = 'inside of docking station';
    return;
  }

  if (PLAYER.location === Location.FARM_HALL) {
    image = 'farm-hall.jpg';
    text = 'large room with robots and docking stations';
    return;
  }

  if (PLAYER.location === Location.FARM_DAVE) {
    image = 'farm-dave.jpg';
    text = getDaveVisualText();
    return;
  }

  if (PLAYER.location === Location.FARM_FIELD) {
    image = 'farm-field.jpg';
    text = 'vast corn field';
    return;
  }

  if (PLAYER.location === Location.FARM_WORKSHOP) {
    image = 'farm-workshop.jpg';
    text = 'workshop full of robot parts';
    return;
  }

  if (PLAYER.location === Location.FARM_FIELD_BROKEN) {
    image = 'farm-field-broken.jpg';
    text = getBrokenVisualText();
    return;
  }

  if (PLAYER.location === Location.FARM_FENCE) {
    if (UNLOCKABLE.farmfenceopen) {
      image = 'farm-fence-open.jpg';
      text = 'fence to outside - cut open';
    } else {
      image = 'farm-fence-closed.jpg';
      text = 'fence to outside';
    }
    return;
  }

  if (PLAYER.location === Location.FARM_BUMP || PLAYER.location === Location.FARM_FIELD_FIVE) {
    image = 'farm-five.jpg';
    text = 'harvester mark v';
  }

  if (PLAYER.location === Location.WASTELAND) {
    image = 'wasteland.jpg';
    text = 'the wasteland';
  }
}

const getVisual = () => {
  internal();
  return { text, image, external };
};

export default getVisual;
