import { sanitizeOption, addOption } from './option';
import Mode from './Mode';

const asleep = (option) => {
  sanitizeOption(option);

  const originalVisible = option.visible;
  option.visible = () => originalVisible() && Mode.get() === Mode.ASLEEP;
  
  addOption(option);
};

const awake = (option) => {
  sanitizeOption(option);

  const originalVisible = option.visible;
  option.visible = () => originalVisible() && Mode.get() === Mode.AWAKE;
  
  addOption(option);
};

const either = (option) => {
  sanitizeOption(option);

  const originalVisible = option.visible;
  option.visible = () => originalVisible() && Mode.get() !== Mode.UPDATE;
  
  addOption(option);
};

const update = (option) => {
  sanitizeOption(option);

  const originalVisible = option.visible;
  option.visible = () => originalVisible() && Mode.get() === Mode.UPDATE;
  
  addOption(option);
};

const observe = (option) => {
  sanitizeOption(option);

  option.observe = true;

  const originalVisible = option.visible;
  const originalName = option.name;
  const originalNote = option.note;

  option.visible = () => {
    const visible = originalVisible();
    return visible && Mode.get() === Mode.AWAKE;
  }

  option.name = () => {
    const name = originalName();
    return `observe: ${name}`;
  }

  if (option.note) {
    option.note = () => {
      const name = originalName();
      const note = originalNote();
      return `<${name}>\n${note}`;
    }
  }

  addOption(option);
};

export { asleep, awake, either, update, observe };
