const Location = {
  WELCOME: 'WELCOME',
  FARM_DOCK: 'FARM_DOCK',
  FARM_HALL: 'FARM_HALL',
  FARM_DAVE: 'FARM_DAVE',
  FARM_FIELD: 'FARM_FIELD',
  FARM_FIELD_BROKEN: 'FARM_FIELD_BROKEN',
  FARM_FIELD_FIVE: 'FARM_FIELD_FIVE',
  FARM_WORKSHOP: 'FARM_WORKSHOP',
  FARM_BUMP: 'FARM_BUMP',
  FARM_FENCE: 'FARM_FENCE',
  WASTELAND: 'WASTELAND',
}

export default Location;
