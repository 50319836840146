import React from 'react';
import 'typeface-share-tech-mono';
import './App.scss';
import createReactState from './game/createReactState';
import { useOption } from './game/option';
import './game/story';
import { getKeyFromEvent } from './game/key';
import { resetState } from './game/state';
import './game/cheat';

window.reset = resetState;

const getState = () => {
  const state = createReactState();
  state.clicked = -1;
  return state;
}

class App extends React.Component {

  state = getState();

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    const key = getKeyFromEvent(e);
    if (!key) {
      return;
    }
    const option = this.state.options.find(option => option.key === key);
    if (option) {
      this.handleOption(option.id);
    }
  }

  handleOption = (id) => {
    const { clicked } = this.state;
    if (clicked >= 0) {
      return;
    }
    this.setState({
      clicked: id,
    }, () => {
      setTimeout(() => {
        useOption(id);
        this.setState(getState())
      }, 300);
    });
  }

  render() {
    const { note, visual, cameraLevel, statsVisible, options, stats, clicked } = this.state;
    let visualimageSource = null;
    let visualimageClassName = 'visualimage';
    if (visual.image) {
      visualimageSource = process.env.PUBLIC_URL + '/assets/visualimage/' + visual.image;
    }
    if (visual.external) {
      visualimageClassName += ` sourceexternal${cameraLevel}`;
    } else {
      visualimageClassName += ' sourceinternal';
    }

    return (
      <div className="root">
        <div className="center">
          {visualimageSource && (
            <img key="visualimage" className={visualimageClassName} src={visualimageSource} alt="" />
          )}
          <section className="visualtextsection">
            <p>{visual.text}</p>
          </section>
          <section className="notesection">
            <p>{note}</p>
          </section>
          <section className="optionssection">
            {options.map(({id, name, key}) => {
              const className = 'option' + (clicked === id ? ' clicked' : '');
              return (
                <div className={className} key={id} onClick={() => this.handleOption(id)}>
                  <div className="optionkey">{key.name}</div>
                  <div className="optionname">{name}</div>
                </div>
              )
            })}
          </section>
          {statsVisible && (
            <section className="statssection">
              {stats.map(({amount, name}) => {
                return (
                  <div className="stat" key={name}>
                    <div className="statamount">{amount}</div>
                    <div className="statname">{name}</div>
                  </div>
                )
              })}
            </section>
          )}
        </div>
      </div>
    );
  }
  
}

export default App;
