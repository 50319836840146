import { SOFTWARE, PLAYER } from './state';

const Mode = {
  ASLEEP: 'ASLEEP',
  UPDATE: 'UPDATE',
  AWAKE: 'AWAKE',
};

Mode.get = () => {
  if (!SOFTWARE.finished) {
      return Mode.UPDATE;
  }
  if (PLAYER.awake) {
      return Mode.AWAKE;
  }
  return Mode.ASLEEP;
};

export default Mode;
