import LocalStorageWrapper from './LocalStorageWrapper';
import Location from './Location';

const createInitialState = () => {
  return {
    gui: {
      note: '...',
    },
    player: {
      location: Location.WELCOME,
      observe: false,
      awake: false,
      unlockable: {
        scan: false,
        field: false,
        corn: false,
        dave: false,
        davename: false,
        davemodel: false,
        davejob: false,
        workshop: false,
        brokenmodel: false,
        brokenstatus: false,
        brokencomplete: false,
        quickcharge: false,
        alphasoftwareavailable: false,
        bumpmodel: false,
        bumpsoftware: false,
        bumpcorn: false,
        bumpcomplete: false,
        fivehascorn: true,
        friendswithdave: false,
        secret: false,
        secretproblem: false,
        secretsolution: false,
        solarcells: false,
        farmfenceopen: false,
      },
      counter: {
        upgrades: 0,
        harvested: 0,
        stolen: 0,
      },
      resources: {
        // corn: 0,
        // goodwill: 0,
      },
      software: {
        current: 1,
        max: 1,
        downloadprogress: 100,
        changelogread: true,
        installprogress: 100,
        finished: true,
      },
      hardware: {
        battery: {
          found: false,
          scanned: false,
          listed: true,
          name: 'battery',
          port: 'MK:AO:XH',
          level: 1,
          current: 0,
          max: 5,
        },
        camera: {
          found: false,
          scanned: false,
          listed: false,
          name: 'camera',
          port: 'WD:NG:5N',
          level: 1,
          current: 1,
          max: 1,
        },
        saw: {
          found: false,
          scanned: false,
          listed: false,
          name: 'saw',
          port: 'ZV:7O:JV',
          level: 0,
          current: 1,
          max: 1,
        },
      },
    }
  }
};

const savedStateStorage = new LocalStorageWrapper('save');

const saveState = () => {
  savedStateStorage.set(STATE);
};

const resetState = () => {
  savedStateStorage.set(null);
  window.location.reload();
}

const STATE = savedStateStorage.get() || createInitialState();
const GUI = STATE.gui;
const PLAYER = STATE.player;
const UNLOCKABLE = STATE.player.unlockable;
const COUNTER = STATE.player.counter;
const RESOURCE = STATE.player.resources;
const SOFTWARE = STATE.player.software;
const HARDWARE = STATE.player.hardware;
const BATTERY = STATE.player.hardware.battery;
const CAMERA = STATE.player.hardware.camera;
const SAW = STATE.player.hardware.saw;

export { STATE, GUI, PLAYER, UNLOCKABLE, COUNTER, RESOURCE, SOFTWARE, HARDWARE, BATTERY, CAMERA, SAW, saveState, resetState };
