class LocalStorageWrapper {
  constructor(key) {
    this.key = key;
  }

  set(value) {
    const json = JSON.stringify(value);
    window.localStorage.setItem(this.key, json);
  }

  get() {
    const json = window.localStorage.getItem(this.key);
    if (json === null || json === undefined) {
      return null;
    }
    try {
      return JSON.parse(json);
    } catch (e) {
      return null;
    }
  }
}

export default LocalStorageWrapper;
