import { UNLOCKABLE, SOFTWARE } from './state';

const getDaveTitle = () => {
  let text = '';
  if (SOFTWARE.current >= 2) {
      text += 'dave the mechanic';
  } else {
    text += 'soft robot';
    if (UNLOCKABLE.davename) {
        text += ' dave';
    }
  }
  return text;
};

const getDaveVisualText = () => {
  let text = getDaveTitle();
  if (SOFTWARE.current < 2) {
    if (UNLOCKABLE.davejob) {
      text += '\nmodel: human\njob: mechanic';
    } else if (UNLOCKABLE.davemodel) {
      text += '\nmodel: human\njob: ???';
    }
  }
  return text;
};

const getBrokenVisualText = () => {
  if (UNLOCKABLE.brokenstatus) {
    return 'broken harvester mark iii'
  }
  if (UNLOCKABLE.brokenmodel) {
    return 'harvester mark iii'
  }
  return '???';
};

export { getDaveTitle, getDaveVisualText, getBrokenVisualText };
