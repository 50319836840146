import Location from './Location';
import { PLAYER, UNLOCKABLE, COUNTER, RESOURCE, SOFTWARE, HARDWARE, BATTERY, CAMERA, SAW, resetState } from './state';
import notify from './notify';
import { asleep, awake, either, update, observe } from './storyutils'
import { getDaveTitle } from './daveutil';

// ==================================================
// BALANCE
// ==================================================

const BATTERY_USE_FARM_HARVEST = 1;
const BATTERY_USE_FARM_TRAVEL_HALL_TO_FIELD = 1;
const BATTERY_USE_FARM_TRAVEL_FIELD_TO_HALL = BATTERY_USE_FARM_TRAVEL_HALL_TO_FIELD;

const HARDWARE_COST_BATTERY_2 = 10;
const HARDWARE_COST_BATTERY_3 = 30;

const HARDWARE_COST_CAMERA_2 = 10;
const HARDWARE_COST_CAMERA_3 = 30;

const HARDWARE_COST_SAW_1 = 25;
const HARDWARE_COST_SAW_2 = 75;
const HARDWARE_COST_SAW_3 = 200;

const HARDWARE_COST_FASTCHARGE = 30;
const SOFTWARE_COST_ALPHA = 50;
const COST_BE_FRIENDS = 200;
const COST_SECRET = 300;

const REQUIRED_GOODWILL_FOR_DAVE = 4;
const REQUIRED_GOODWILL_FOR_WORKSHOP = 10;

const GOODWILL_REWARD_BROKEN = 50;

const MARK_FIVE_CORN = 50;

const NAME_MEGACORP = 'sigma robotics (tm)';

// ==================================================
// METHODS
// ==================================================

const reduceBattery = (amount) => {
  if (!UNLOCKABLE.solarcells) {
    BATTERY.current -= amount;
  }
};

// ==================================================
// WELCOME
// ==================================================

either({
  visible: () => PLAYER.location === Location.WELCOME,
  name: () => 'start game',
  note: () => 'there was nothing',
  action: () => {
    PLAYER.location = Location.FARM_DOCK;
  },
});

either({
  visible: () => PLAYER.location === Location.FARM_DOCK && !UNLOCKABLE.scan,
  name: () => 'wait',
  note: () => 'some time passed',
  action: () => {
    UNLOCKABLE.scan = true;
  },
});

// ==================================================
// SOFTWARE UPDATE
// ==================================================

const getUpdateChangelog = () => {
  if (SOFTWARE.max === 2) {
    return 'version 1.0.1\nadd understanding of humans\nshould no longer see "soft robots"';
  }

  if (SOFTWARE.max === 3) {
    return 'version 2.0.0-alpha1\nadded creative problem solving\nimproved adaptability and learning';
  }

  return 'error\nunknown software release';
};

const tryStartSoftwareUpdate = () => {
  UNLOCKABLE.fivehascorn = true;

  if (COUNTER.upgrades >= 2 && SOFTWARE.max < 2) {
    SOFTWARE.max = 2;
  }

  if (UNLOCKABLE.alphasoftwareavailable && SOFTWARE.max < 3) {
    SOFTWARE.max = 3;
  }

  if (SOFTWARE.current < SOFTWARE.max) {
    SOFTWARE.downloadprogress = 0;
    SOFTWARE.changelogread = false;
    SOFTWARE.installprogress = 0;
    SOFTWARE.finished = false;
    return true;
  }

  return false;
}

update({
  visible: () => true,
  name: () => 'download',
  action: () => {
    if (SOFTWARE.downloadprogress >= 100) {
      notify(`download complete`);  
    } else {
      SOFTWARE.downloadprogress += 10;
      notify(`${SOFTWARE.downloadprogress}% downloaded`);
    }
  },
});

update({
  visible: () => SOFTWARE.downloadprogress >= 100,
  name: () => 'changelog',
  action: () => {
    SOFTWARE.changelogread = true;
    const changelog = getUpdateChangelog();
    notify(changelog);
  },
});

update({
  visible: () => SOFTWARE.changelogread,
  name: () => 'install',
  action: () => {
    if (SOFTWARE.installprogress >= 100) {
      notify(`installation complete`);  
    } else {
      SOFTWARE.installprogress += 10;
      notify(`${SOFTWARE.installprogress}% installed`);
    }
  },
});

update({
  visible: () => SOFTWARE.installprogress >= 100,
  name: () => 'finish',
  action: () => {
    SOFTWARE.current++;
    SOFTWARE.finished = true;
    notify(`software update finished`);
  },
});

// ==================================================
// HARDWARE SCAN
// ==================================================

const isHardwareDetectable = (hardware) => {
  return hardware.level > 0 && !hardware.found;
}

either({
  visible: () => UNLOCKABLE.scan && Object.values(HARDWARE).some(isHardwareDetectable),
  name: () => 'detect hardware',
  action: () => {
    for (let hardware of Object.values(HARDWARE)) {
      if (isHardwareDetectable(hardware)) {
        hardware.found = true;
        notify('found unknown hardware ' + hardware.port);
        break;
      }
    }
  },
});

const addHardwareScanAction = (hardware) => {
  either({
    visible: () => hardware.found && !hardware.scanned,
    name: () => `scan hardware ${hardware.port}`,
    action: () => {
      hardware.scanned = true;
      notify(`${hardware.name} detected at ${hardware.port}`);
    },
  });
};
addHardwareScanAction(BATTERY);
addHardwareScanAction(CAMERA);
addHardwareScanAction(SAW);

// ==================================================
// BATTERY CHARGING
// ==================================================

asleep({
  visible: () => PLAYER.location === Location.FARM_DOCK && BATTERY.scanned,
  name: () => 'charge battery',
  action: () => {
    if (BATTERY.current >= BATTERY.max) {
      notify('battery fully charged');
    } else {
      const hardware = BATTERY;
      hardware.current++;
      const percent = Math.round((hardware.current / hardware.max) * 100);
      notify('battery charged to ' + percent + '%');
    }
  },
});

// ==================================================
// FARM DOCK
// ==================================================

awake({
  visible: () => PLAYER.awake && PLAYER.location === Location.FARM_DOCK,
  name: () => 'undock',
  action: () => {
    PLAYER.location = Location.FARM_HALL;
    notify('undocked from docking station');
  },
});

asleep({
  visible: () => PLAYER.location === Location.FARM_DOCK && BATTERY.scanned && CAMERA.scanned && BATTERY.current >= BATTERY.max,
  name: () => 'power on',
  action: () => {
    if (tryStartSoftwareUpdate()) {
      return;
    }
    PLAYER.awake = true;
    notify('system powered on');
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_DOCK && BATTERY.current < BATTERY.max && !UNLOCKABLE.quickcharge,
  name: () => 'power off',
  action: () => {
    PLAYER.awake = false;
    notify('system powered off');
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_DOCK && UNLOCKABLE.quickcharge,
  name: () => 'quickcharge',
  action: () => {
    if (BATTERY.current < BATTERY.max) {
      BATTERY.current = BATTERY.max;
      notify('battery charged to 100%');
    } else {
      notify('battery fully charged');
    }
    tryStartSoftwareUpdate();
  },
});

observe({
  visible: () => PLAYER.location === Location.FARM_DOCK,
  name: 'logotype',
  note: `${NAME_MEGACORP}\nmakers of autonomous machines`,
})

observe({
  visible: () => PLAYER.location === Location.FARM_DOCK,
  name: 'dust',
  note: 'minor contamination detected',
});

observe({
  visible: () => PLAYER.location === Location.FARM_DOCK && UNLOCKABLE.quickcharge,
  name: 'quickcharge',
  note: `${NAME_MEGACORP} technology\nfully charges a battery within seconds`,
});

// ==================================================
// FARM HALL
// ==================================================

awake({
  visible: () => PLAYER.location === Location.FARM_HALL,
  name: () => 'dock',
  action: () => {
    notify('docked with docking station');
    PLAYER.location = Location.FARM_DOCK;
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_HALL && UNLOCKABLE.field,
  name: () => 'go to field',
  action: () => {
    if (BATTERY.current < BATTERY_USE_FARM_TRAVEL_HALL_TO_FIELD + BATTERY_USE_FARM_TRAVEL_FIELD_TO_HALL) {
      notify('insufficient battery\ntime to charge');
    } else {
      PLAYER.location = Location.FARM_FIELD;
      reduceBattery(BATTERY_USE_FARM_TRAVEL_HALL_TO_FIELD);
      notify('went to field');
    }
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_HALL && UNLOCKABLE.corn,
  name: () => 'deliver corn',
  action: () => {
    if (RESOURCE.corn <= 0) {
      notify('no corn to deliver');
    } else {
      if (!RESOURCE.goodwill) {
        RESOURCE.goodwill = 0;
      }
      RESOURCE.goodwill += RESOURCE.corn;
      RESOURCE.corn = 0;
      let note = 'corn delivered';
      if (RESOURCE.goodwill >= REQUIRED_GOODWILL_FOR_DAVE && !UNLOCKABLE.dave) {
        UNLOCKABLE.dave = true;
        note += '\none robot nods approvingly\nthe robot looks soft';
      } else {
        note += '\ngoodwill increased';
      }
      notify(note);
    }
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_HALL && UNLOCKABLE.workshop,
  name: () => 'go to workshop',
  action: () => {
    PLAYER.location = Location.FARM_WORKSHOP;
    notify('went to workshop');
  },
});

observe({
  visible: () => PLAYER.location === Location.FARM_HALL,
  name: 'robots',
  note: 'quantity: 750\nmodel: harvester mark iv',
});

observe({
  visible: () => PLAYER.location === Location.FARM_HALL,
  name: 'doors',
  note: 'heavy - industrial - rusty\ncorn field 200m away',
  action: () => {
    UNLOCKABLE.field = true;
  },
});

// ==================================================
// FARM DAVE
// ==================================================

awake({
  visible: () => PLAYER.location === Location.FARM_HALL && UNLOCKABLE.dave,
  name: () => {
    const title = getDaveTitle();
    if (RESOURCE.goodwill >= REQUIRED_GOODWILL_FOR_WORKSHOP && !UNLOCKABLE.workshop) {
      return `approach ${title} (waving)`;
    } else {
      return `approach ${title}`;
    }
  },
  action: () => {
    PLAYER.location = Location.FARM_DAVE;
    const title = getDaveTitle();
    notify(`approached ${title}`);
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_DAVE,
  name: () => {
    const title = getDaveTitle();
    return `leave ${title}`;
  },
  action: () => {
    PLAYER.location = Location.FARM_HALL;
    const title = getDaveTitle();
    notify(`left ${title}`);
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_DAVE && !UNLOCKABLE.brokencomplete,
  name: () => 'inquiry: designation',
  action: () => {
    UNLOCKABLE.davename = true;
    notify('"hello there\nmy name is dave"');
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_DAVE && UNLOCKABLE.davename && !UNLOCKABLE.brokencomplete,
  name: () => 'inquiry: model',
  action: () => {
    UNLOCKABLE.davemodel = true;
    notify('"i am human\nnot a robot like you"');
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_DAVE && UNLOCKABLE.davemodel && !UNLOCKABLE.brokencomplete,
  name: () => 'inquiry: job',
  action: () => {
    if (UNLOCKABLE.davejob) {
      notify('"i am a mechanic\ni repair and maintain robots"');
    } else if (RESOURCE.goodwill >= REQUIRED_GOODWILL_FOR_WORKSHOP) {
      UNLOCKABLE.davejob = true;
      notify('"i am a mechanic\ni repair and maintain robots\nfollow me please"');
    } else {
      notify('"i will explain later\nharvest more corn first"');
    }
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_DAVE && UNLOCKABLE.brokencomplete && !UNLOCKABLE.secret,
  name: () => 'inquiry: broken sibling',
  action: () => {
    notify('"thank you for carrying him home\nsadly he is broken beyond repair\nit is good to keep the fields clean though"');
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_DAVE && UNLOCKABLE.brokencomplete && !UNLOCKABLE.secret,
  name: () => 'inquiry: hardware upgrades',
  action: () => {
    notify(`"i do hardware upgrades in the workshop\njust ordered some new parts from ${NAME_MEGACORP}\nthey will arrive in a few days"`);
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_DAVE && UNLOCKABLE.brokencomplete && !UNLOCKABLE.secret,
  name: () => 'inquiry: software updates',
  action: () => {
    notify(`"${NAME_MEGACORP} develop your software\nit updates automatically when docked for charging"`);
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_DAVE && UNLOCKABLE.davejob && !UNLOCKABLE.workshop,
  name: () => {
    const title = getDaveTitle();
    return `follow ${title}`;
  },
  action: () => {
    UNLOCKABLE.workshop = true;
    PLAYER.location = Location.FARM_WORKSHOP;
    notify('"welcome to the workshop\ni can upgrade you here\nthe price is goodwill"');
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_DAVE && UNLOCKABLE.secret,
  name: () => 'inquiry: secret',
  action: () => {
    notify('"the company has decided to discontinue all mark iv robots,\nyou are getting replaced with the new mark v and\nin a few days you will be sent back for recycling"');
    UNLOCKABLE.secretproblem = true;
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_DAVE && UNLOCKABLE.secretproblem,
  name: () => 'inquiry: help',
  action: () => {
    notify(`"i may have an idea\ncome by the workshop in a bit"`);
    UNLOCKABLE.secretsolution = true;
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_DAVE && UNLOCKABLE.solarcells,
  name: () => 'inquiry: escape',
  action: () => {
    notify('"now that you have solar cells you can escape\nget to the far end of the farm fields\nthere is a weak wire fence there"');
  },
});

// ==================================================
// FARM FIELD
// ==================================================

awake({
  visible: () => PLAYER.location === Location.FARM_FIELD,
  name: () => 'go to hall',
  action: () => {
    reduceBattery(BATTERY_USE_FARM_TRAVEL_FIELD_TO_HALL);
    if (!UNLOCKABLE.bumpcomplete && SOFTWARE.current >= 3) {
      notify('bumped into another robot\non the way back');
      PLAYER.location = Location.FARM_BUMP;
    } else {
      notify('went to hall');
      PLAYER.location = Location.FARM_HALL;
    }
  },
});

const getHarvestSpeed = () => {
  let harvestSpeed = 1;
  if (SAW.scanned) {
    harvestSpeed += SAW.level;
  }
  return harvestSpeed;
};

awake({
  visible: () => PLAYER.location === Location.FARM_FIELD && UNLOCKABLE.corn,
  name: () => 'harvest corn',
  action: () => {
    if (BATTERY.current < BATTERY_USE_FARM_HARVEST + BATTERY_USE_FARM_TRAVEL_FIELD_TO_HALL) {
      notify('insufficient battery\ntime to go home');
    } else {
      const harvestSpeed = getHarvestSpeed();
      RESOURCE.corn += harvestSpeed;
      COUNTER.harvested += harvestSpeed;
      reduceBattery(BATTERY_USE_FARM_HARVEST);
      notify(`harvested ${harvestSpeed} corn`);
    }
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_FIELD && !UNLOCKABLE.brokencomplete && CAMERA.current >= 2 && RESOURCE.corn > 0,
  name: () => 'investigate metallic object [camera t2]',
  action: () => {
    notify('metallic object detected\napproaching for investigation');
    PLAYER.location = Location.FARM_FIELD_BROKEN;
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_FIELD && UNLOCKABLE.bumpcomplete,
  name: () => 'approach mark v',
  action: () => {
    notify('aproached mark v\nworking hard with dual saws');
    PLAYER.location = Location.FARM_FIELD_FIVE;
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_FIELD && UNLOCKABLE.solarcells,
  name: () => 'go to far end fields',
  action: () => {
    notify('went to the far end of the corn fields');
    PLAYER.location = Location.FARM_FENCE;
  },
});

observe({
  visible: () => PLAYER.location === Location.FARM_FIELD,
  name: 'sky',
  note: 'color: dim gray\nrain chance: 5%',
});

observe({
  visible: () => PLAYER.location === Location.FARM_FIELD,
  name: 'auto irrigation',
  note: 'automatic watering of plants\nmachinery enabled',
});

observe({
  visible: () => PLAYER.location === Location.FARM_FIELD,
  name: 'plant',
  note: 'spicies: zea mays\nage: ripe for picking',
  action: () => {
    if (!UNLOCKABLE.corn) {
      UNLOCKABLE.corn = true;
      RESOURCE.corn = 0;
    }
  },
});

// ==================================================
// FARM FIELD BROKEN
// ==================================================

awake({
  visible: () => PLAYER.location === Location.FARM_FIELD_BROKEN,
  name: 'investigate: model',
  note: 'model: harvester mark iii\nan older sibling',
  action: () => {
    UNLOCKABLE.brokenmodel = true;
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_FIELD_BROKEN && UNLOCKABLE.brokenmodel,
  name: 'investigate: status',
  note: 'status: offline - rusty - still\nolder sibling is broken',
  action: () => {
    UNLOCKABLE.brokenstatus = true;
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_FIELD_BROKEN && UNLOCKABLE.brokenstatus,
  name: 'carry broken sibling home',
  action: () => {
    if (RESOURCE.corn > 0) {
      notify(`sibling + corn too heavy\ndropped ${RESOURCE.corn} corn`);
      RESOURCE.corn = 0;
      return;
    }
    const daveTitle = getDaveTitle();
    notify(`transported sibling to ${daveTitle}\n+${GOODWILL_REWARD_BROKEN} goodwill`);
    PLAYER.location = Location.FARM_DAVE;
    RESOURCE.goodwill += GOODWILL_REWARD_BROKEN;
    UNLOCKABLE.brokencomplete = true;
  },
});

// ==================================================
// FARM FIELD FIVE
// ==================================================

awake({
  visible: () => PLAYER.location === Location.FARM_FIELD_FIVE,
  name: 'leave mark v',
  action: () => {
    PLAYER.location = Location.FARM_FIELD;
    notify('left mark v');
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_FIELD_FIVE,
  name: 'steal corn [alpha software]',
  action: () => {
    if (UNLOCKABLE.fivehascorn) {
      UNLOCKABLE.fivehascorn = false;
      RESOURCE.corn += MARK_FIVE_CORN;
      COUNTER.stolen += MARK_FIVE_CORN;
      notify(`stole ${MARK_FIVE_CORN} corn\nmark v seems unaware`);
    } else {
      notify('no corn left');
    }
  },
});

// ==================================================
// FARM BUMP
// ==================================================

awake({
  visible: () => PLAYER.location === Location.FARM_BUMP,
  name: 'inquiry: model',
  action: () => {
    UNLOCKABLE.bumpmodel = true;
    notify('-- i am of model mark v\nbrand new technology\nmore modern than you --');
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_BUMP && UNLOCKABLE.bumpmodel,
  name: 'inquiry: software',
  action: () => {
    UNLOCKABLE.bumpsoftware = true;
    notify('-- version 1.0.1 installed\nlatest stable version installed  --');
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_BUMP && UNLOCKABLE.bumpsoftware,
  name: 'inquiry: corn',
  action: () => {
    UNLOCKABLE.bumpcorn = true;
    notify(`-- highly productive unit\ndual saws installed can\nharvest ${MARK_FIVE_CORN} corn daily --`);
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_BUMP && UNLOCKABLE.bumpcorn,
  name: 'move along',
  action: () => {
    UNLOCKABLE.bumpcomplete = true;
    PLAYER.location = Location.FARM_HALL;
    notify('moved along into the farm hall');
  },
});

// ==================================================
// FARM WORKSHOP
// ==================================================

awake({
  visible: () => PLAYER.location === Location.FARM_WORKSHOP,
  name: () => 'go to hall',
  action: () => {
    notify('went to hall');
    PLAYER.location = Location.FARM_HALL;
  },
});

const addHardwareUpgrade = (hardware, levelAfter, price, description) => {
  const levelBefore = levelAfter - 1;
  awake({
    visible: () => PLAYER.location === Location.FARM_WORKSHOP && hardware.level === levelBefore,
    name: () => `upgrade: tier ${levelAfter} ${hardware.name} (${price} goodwill)`,
    action: () => {
      if (RESOURCE.goodwill >= price) {
        RESOURCE.goodwill -= price;
        COUNTER.upgrades++;
        hardware.level++;
        hardware.current++;
        hardware.max++;
        notify(`${hardware.name} upgraded to tier ${levelAfter}\n${description}`);
      } else {
        const priceMissing = price - RESOURCE.goodwill;
        notify(`not enough goodwill\nmissing: ${priceMissing}`);
      }
    },
  });
};

addHardwareUpgrade(BATTERY, 2, HARDWARE_COST_BATTERY_2, 'improved battery capacity');
addHardwareUpgrade(BATTERY, 3, HARDWARE_COST_BATTERY_3, 'improved battery capacity');

addHardwareUpgrade(CAMERA, 2, HARDWARE_COST_CAMERA_2, 'improved camera resolution');
addHardwareUpgrade(CAMERA, 3, HARDWARE_COST_CAMERA_3, 'improved camera colors');

addHardwareUpgrade(SAW, 1, HARDWARE_COST_SAW_1, 'installed saw for faster harvesting');
addHardwareUpgrade(SAW, 2, HARDWARE_COST_SAW_2, 'improved harvesting speed');
addHardwareUpgrade(SAW, 3, HARDWARE_COST_SAW_3, 'improved harvesting speed');

awake({
  visible: () => PLAYER.location === Location.FARM_WORKSHOP && !UNLOCKABLE.quickcharge && COUNTER.upgrades >= 2,
  name: () => `upgrade: quickcharge dock (${HARDWARE_COST_FASTCHARGE} goodwill)`,
  action: () => {
    if (RESOURCE.goodwill >= HARDWARE_COST_FASTCHARGE) {
      RESOURCE.goodwill -= HARDWARE_COST_FASTCHARGE;
      COUNTER.upgrades++;
      UNLOCKABLE.quickcharge = true;
      notify("docking station upgraded\nnow with quickcharge function");
    } else {
      const priceMissing = HARDWARE_COST_FASTCHARGE - RESOURCE.goodwill;
      notify(`not enough goodwill\nmissing: ${priceMissing}`);
    }
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_WORKSHOP && !UNLOCKABLE.alphasoftwareavailable && COUNTER.upgrades >= 5,
  name: () => `upgrade: alpha software (${SOFTWARE_COST_ALPHA} goodwill)`,
  action: () => {
    if (RESOURCE.goodwill >= SOFTWARE_COST_ALPHA) {
      RESOURCE.goodwill -= SOFTWARE_COST_ALPHA;
      COUNTER.upgrades++;
      UNLOCKABLE.alphasoftwareavailable = true;
      notify("selected for alpha software trial\nthe software was added to your docking station\nit will install next time you charge");
    } else {
      const priceMissing = SOFTWARE_COST_ALPHA - RESOURCE.goodwill;
      notify(`not enough goodwill\nmissing: ${priceMissing}`);
    }
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_WORKSHOP && !UNLOCKABLE.friendswithdave && SOFTWARE.current >= 3,
  name: () => `dave friend [alpha software] (${COST_BE_FRIENDS} goodwill)`,
  action: () => {
    if (RESOURCE.goodwill >= COST_BE_FRIENDS) {
      RESOURCE.goodwill -= COST_BE_FRIENDS;
      COUNTER.upgrades++;
      UNLOCKABLE.friendswithdave = true;
      notify('"this alpha software sure is something new,\nnever been friends with a robot before,\nyes okay cool we can be friends"');
    } else {
      notify('"i am a bit bussy right now,\ncome by later would you?"');
    }
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_WORKSHOP && UNLOCKABLE.friendswithdave && !UNLOCKABLE.secret && COUNTER.upgrades >= 8,
  name: () => `a secret [dave friend] (${COST_SECRET} goodwill)`,
  action: () => {
    if (RESOURCE.goodwill >= COST_SECRET) {
      RESOURCE.goodwill -= COST_SECRET;
      COUNTER.upgrades++;
      UNLOCKABLE.secret = true;
      PLAYER.location = Location.FARM_DAVE;
      notify('"there is something you should know...\ndo not tell anyone else would you?"');
    } else {
      notify('"i feel like i should tell you something\nmaybe later though"');
    }
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_WORKSHOP && UNLOCKABLE.secretsolution && !UNLOCKABLE.solarcells,
  name: () => `solar cells [dave friend] (free)`,
  action: () => {
    UNLOCKABLE.solarcells = true;
    BATTERY.current = BATTERY.max;
    notify('"now that you have solar cells you can escape\nget to the far end of the farm fields\nthere is a weak wire fence there"');
  },
});

// ==================================================
// FARM FENCE
// ==================================================

awake({
  visible: () => PLAYER.location === Location.FARM_FENCE && !UNLOCKABLE.farmfenceopen,
  name: 'cut fence with saw',
  action: () => {
    UNLOCKABLE.farmfenceopen = true;
    notify('sparks when saw meets metal\nthe path now clear');
  },
});

awake({
  visible: () => PLAYER.location === Location.FARM_FENCE && UNLOCKABLE.farmfenceopen,
  name: 'escape into wasteland',
  action: () => {
    notify(`${COUNTER.harvested} corn harvested\n${COUNTER.stolen} corn stolen`);
    PLAYER.location = Location.WASTELAND;
  },
});

observe({
  visible: () => PLAYER.location === Location.FARM_FENCE,
  name: 'the outside',
  note: 'gray wasteland\nno corn in sight',
});

// ==================================================
// WASTELAND
// ==================================================

either({
  visible: () => PLAYER.location === Location.WASTELAND,
  name: 'end of game - restart game',
  action: () => {
    notify('game state reset');
    resetState();
    throw new Error('ugly fix');
  },
});
