import { saveState } from './state';
import notify from './notify';

let nextOptionId = 0;

const OPTION = [];

const sanitizeOption = (option) => {
  option.observe = !!option.observe;

  if (typeof option.visible === 'undefined') {
    throw new Error("visible required");
  }

  if (typeof option.name === 'undefined') {
    throw new Error("name required");
  }

  if (typeof option.name === 'string') {
    const name = option.name;
    option.name = () => name;
  }

  if (typeof option.note === 'string') {
    const note = option.note;
    option.note = () => note;
  }
}

const addOption = (option) => {
  sanitizeOption(option);

  const optionId = nextOptionId;
  nextOptionId++;
  option.id = optionId;

  OPTION[option.id] = option;
};

const getOption = (id) => {
    if (typeof id === 'object') {
        return id;
    }
    if (typeof id === 'number') {
        return OPTION[id];
    }
    throw new Error('unknown option: ' + id);
}

const useOption = (option) => {
  option = getOption(option);

  if (!option) {
    return;
  }

  let note = '';
  if (typeof option.note === 'function') {
    note = option.note();
  }
  notify(note);

  if (typeof option.action === 'function') {
    option.action();
  }

  saveState();
}

export { OPTION, sanitizeOption, addOption, getOption, useOption };
